import { ReactComponent as next } from "./assets/icons/next.svg";
import { ReactComponent as list } from "./assets/icons/list.svg";
import { ReactComponent as read } from "./assets/icons/read.svg";
import { ReactComponent as activities } from "./assets/icons/activities.svg";
import { ReactComponent as collobration } from "./assets/icons/collobration.svg";
import { ReactComponent as close } from "./assets/icons/close.svg";
import { ReactComponent as cubes } from "./assets/icons/cubes.svg";
import { ReactComponent as cubesHover } from "./assets/icons/cubes-hover.svg";
import { ReactComponent as idea } from "./assets/icons/idea.svg";
import { ReactComponent as ideaHover } from "./assets/icons/idea-hover.svg";
import { ReactComponent as baby1 } from "./assets/icons/baby1.svg";
import { ReactComponent as baby2 } from "./assets/icons/baby2.svg";
import { ReactComponent as baby3 } from "./assets/icons/baby3.svg";
import { ReactComponent as baby4 } from "./assets/icons/baby4.svg";
import { ReactComponent as baby5 } from "./assets/icons/baby5.svg";
import { ReactComponent as baby6 } from "./assets/icons/baby6.svg";
import { ReactComponent as baby7 } from "./assets/icons/baby7.svg";
import { ReactComponent as baby8 } from "./assets/icons/baby8.svg";
import { ReactComponent as baby9 } from "./assets/icons/baby9.svg";
import { ReactComponent as baby10 } from "./assets/icons/baby10.svg";

import { ReactComponent as caregiver } from "./assets/icons/caregiver.svg";
import { ReactComponent as song } from "./assets/icons/song.svg";
import { ReactComponent as checkmarkgreen } from "./assets/icons/checkmark-green.svg";
import { ReactComponent as checkmarkPurple } from "./assets/icons/checkmark-gradient.svg";
import { ReactComponent as download } from "./assets/icons/download.svg";
import { ReactComponent as videoIcon } from "./assets/icons/play-btn.svg";
import { ReactComponent as calendar } from "./assets/icons/calendar-1.svg";
import { ReactComponent as calendar1 } from "./assets/icons/calendar.svg";
import { ReactComponent as whiteCheckMark } from "./assets/icons/white-checmark.svg";
import { ReactComponent as parentTalk } from "./assets/icons/parent/talk.svg";
import { ReactComponent as download2 } from "./assets/icons/download2.svg";
import { ReactComponent as zoomin } from "./assets/icons/zoomin.svg";
import { ReactComponent as witchSteak } from "./assets/icons/witchSteak.svg";
import { ReactComponent as parentMove } from "./assets/icons/parent/move.svg";
import { ReactComponent as parentPretend } from "./assets/icons/parent/pretend.svg";
import { ReactComponent as gradientCircle } from "./assets/icons/checkmark-gradient.svg";
import { ReactComponent as f_read } from "./assets/icons/facilitator/read.svg";
import { ReactComponent as f_talk } from "./assets/icons/facilitator/talk.svg";
import { ReactComponent as f_sing } from "./assets/icons/facilitator/sing.svg";
import { ReactComponent as f_move } from "./assets/icons/facilitator/move.svg";
import { ReactComponent as f_pretend } from "./assets/icons/facilitator/pretend.svg";
import { ReactComponent as f_explore } from "./assets/icons/facilitator/explore.svg";
import { ReactComponent as f_create } from "./assets/icons/facilitator/create.svg";
import { ReactComponent as f_solve } from "./assets/icons/facilitator/solve.svg";
import { ReactComponent as googleIcon } from "./assets/icons/google-icon.svg";
import { ReactComponent as googleIconAr } from "./assets/icons/google_ar.svg";
import { ReactComponent as global } from "./assets/icons/svgexport-10.svg";
import { ReactComponent as steakHover } from "./assets/icons/star-hover.svg";
import { ReactComponent as pause } from "./assets/icons/pause.svg";
import { ReactComponent as arrow } from "./assets/icons/arrow.svg";
import { ReactComponent as bars } from "./assets/icons/bars.svg";
import { ReactComponent as darkBars } from "./assets/icons/bars-black.svg";
import { ReactComponent as checklistIcon } from "./assets/icons/checklist.svg";
import { ReactComponent as paginationArrow } from "./assets/icons/left-arrow.svg";
import { ReactComponent as activityVideoIcon } from "./assets/icons/video-icon.svg";
import { ReactComponent as localization } from "./assets/icons/localization.svg";
import { ReactComponent as logoColored } from "./assets/icons/logo-new.svg"
import { ReactComponent as checkMark } from "./assets/icons/check-mark.svg"
import { ReactComponent as user } from "./assets/images/user.svg"



import gradientCheckmark from "./assets/icons/checkmark-gradient.png";
import shape1 from "./assets/icons/who-we-are-glyphs-triangle.png";
import shape2 from "./assets/icons/who-we-are-glyphs-triangle-2.png";
import shape3 from "./assets/icons/who-we-are-glyphs-triangle-3.png";
import shape4 from "./assets/icons/who-we-are-glyphs-triangle-4.png";

import discovery from "./assets/icons/logos/discovery.png";
import educationMinistry from "./assets/icons/logos/ministry-of-education.png";
import nationalChildhood from "./assets/icons/logos/national-early-childhood.png";
import socialSolidarity from "./assets/icons/logos/social-solidarity.png";
import child2 from "./assets/images/pexels-alexander-dummer-1912868.jpg";
import child4 from "./assets/images/pexels-andrea-piacquadio-1843358.jpg";
import child3 from "./assets/images/pexels-jonas-mohamadi-1427288.png";
import child1 from "./assets/images/pexels-pixabay-266004.jpg";
import bluePattren from "./assets/images/blue-patten-bg.png";
import purbleBg from "./assets/images/serving-world-classrooms-background.png";
import readingGuidecover from "./assets/images/STEMEY_MMC_fPSC_Week1-4_Page_01.png";
import childrenHeroSection from "./assets/images/parents-cover.png";
import mainPurbleBg from "./assets/images/purble-bg.png";
import greenBg from "./assets/images/green-bg.png";
import logo from "./assets/icons/logo.png";
import logoDark from "./assets/icons/logo-dark.png";

import bgImage from "./assets/images/bg-modal-image.png";
import careGiverImage from "./assets/images/lgbt-mothers-home-drawing-with-their-children.png";
import parentActivities from "./assets/images/parent-activities.png";
import weeklyTogether from "./assets/images/week_together.png";
import fictional from "./assets/images/fictional.png";
import FG from "./assets/images/FG_EN_tmb.png";
import nurseryChildren from "./assets/images/nursery.png";
import avatarImage from "./assets/images/avatar.png";
import logoColored2 from "./assets/icons/logo-colored.png";
import pfg_ar from "./assets/images/PFG_AR_tmb.jpg";
import pfg_en from "./assets/images/PFG_EN_tmb.jpg";






export default {
	fictional,
	weeklyTogether,
	parentActivities,
	bluePattren,
	next,
	zoomin,
	list,
	read,
	download2,
	activities,
	collobration,
	cubes,
	cubesHover,
	idea,
	ideaHover,
	educationMinistry,
	nationalChildhood,
	socialSolidarity,
	discovery,
	logo,
	child1,
	child2,
	child3,
	witchSteak,
	child4,
	baby2,
	baby3,
	baby4,
	baby5,
	baby6,
	baby7,
	baby8,
	baby9,
	baby10,
	logoDark,
	baby1,
	caregiver,
	song,
	purbleBg,
	readingGuidecover,
	checkmarkgreen,
	download,
	videoIcon,
	calendar,
	calendar1,
	parentMove,
	parentTalk,
	parentPretend,
	childrenHeroSection,
	checkmarkPurple,
	shape1,
	shape2,
	mainPurbleBg,
	greenBg,
	careGiverImage,
	whiteCheckMark,
	gradientCheckmark,
	f_read,
	f_create,
	f_sing,
	f_talk,
	f_pretend,
	f_move,
	f_solve,
	f_explore,
	googleIcon,
	googleIconAr,
	global,
	gradientCircle,
	steakHover,
	pause,
	close,
	arrow,
	bgImage,
	bars,
	darkBars,
	shape3,
	shape4,
	checklistIcon,
	paginationArrow,
	activityVideoIcon,
	localization,
	logoColored,
	FG,
	nurseryChildren,
	avatarImage,
	checkMark,
	logoColored2,
	pfg_ar,
	pfg_en,
	user

};
