import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { constants } from "../constants";
export const API_getParentData = (module) => {
	return async () => {
		try {
			const response = await axios.get(`${baseUrl}/parent/${module}`);
			return response;
		} catch (error) {
			return error.response;
		}
	};
};

export const API_getParentWeeks = (module, week) => {
	return async () => {
		try {
			const response = await axios.get(`${baseUrl}/parent/${module}/week/${week}`);
			return response;
		} catch (error) {
			return error.response;
		}
	};
};