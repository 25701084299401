import React from "react";
import classes from "./index.module.scss";
import images from "../../../../resources/images";
import ResourceCard from "../../../../components/ResourceCard";
import DayCard from "../../../ParentWeek/components/DayCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FacilitatorAccordion = ({ days, weeklyResources, module, week }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<section className="p-inline">

							<h3 className="f-28 semi-bold my-5">{t("Suggested weekly activities")}</h3>
							<div>
								<h4 className="meduim text-center f-20 text-blue">{t("Activities to do with your class")}</h4>
								{days?.map((day, index) => (
									<DayCard
										title="Talk Together"
										icon={<images.parentTalk />}
										color="#3F9CBB"
										isFacilitator={true}
										subTitle="I am special"
										item={day}
										key={index}
										module={module}
										week={week}
									/>
								))}
							</div>

							<div className={classes.resources}>
								<div>
									<h3 className="f-28 semi-bold">{t("More Resources")}</h3>
									<p className="f-18 meduim">
										{t("For more resources you can explore full guidance and videos we provided for the module")}
									</p>
								</div>
								<div className={classes.resources_wrapper}>
									{weeklyResources.map((resource, index) => resource.type != "Video" && <ResourceCard item={resource} key={index}  module={module} week={week} />)}
								</div>
							</div>

			</section>
		</React.Fragment>
	);
};

export default FacilitatorAccordion;
