import React from "react";
import images from "../../../../resources/images";
import ActivityCard from '../../../../components/ActivityCard';
import classes from './index.module.scss';
import { useSelector } from "react-redux";
const DayCard=({item,title, icon, color, isParent, isFacilitator, subTitle, week, module})=>{
    const lang = useSelector((state) => state.settingsReducer.lang);

    return(
        <React.Fragment>
        <div className={classes.card}>
                <div className={classes.day}>
                    <images.calendar/>
                    <span className="semi-bold text-blue f-20">{lang=="en"?item.title:item.title_arabic}</span>
                </div>
                <div className={classes.content}>
                    {item.activities?.map((activity, index)=>(
                        <ActivityCard title={lang=="en"?item.title:item.title_arabic} icon={icon} color={color} hasList={isParent?true:false} activity={activity} key={index} isFacilitator={isFacilitator} segment={activity.has_segment} week={week} module={module}/>
                    ))}


                </div>

        </div>
        <hr/>
        </React.Fragment>
    )

}

export default DayCard