import React, { Fragment, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { t } from "i18next";
import { API_getChannelData } from "../../store/actions/channelActions";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate

} from "react-router-dom";


import ChannelTabs from "./Components/ChannelTabs";
import Pagination from "../../components/Pagination";
import { useSelector } from "react-redux";



const Channel = () => {
  let {slug}= useParams();
  const lang = useSelector((state) => state.settingsReducer.lang);

  const [channelData, setChannelData] = useState([]);
  const dispatch = useDispatch();
  const[type, setType]=useState(slug)
  const [loading, setLoading] = useState();
  const navigate=useNavigate();
  const[currentPage, setCurrentPage]=useState(1)

  const tabs=[

  {
    eventKey:"weekly_together",
    title:"Weekly Together",
    title_arabic: "ممارسات الميسر الأسبوعية معًا"
  },
  {
    eventKey:"parent_activities",
    title:"Parents/Families Activities",
    title_arabic: "أنشطة ولي الأمر"
    },
  {
    eventKey:"fictional_story",
    title:"Fictional Story",
    title_arabic: "القصص الخيالية",
  },
  {
    eventKey:"pd_videos",
    title:"Facilitator PD Videos",
    title_arabic: "خطط التنمية الشخصية للميسرة",
  },

  ]
console.log('slug', slug)


  useEffect(async () => {
    setLoading(true)
    const res = await dispatch(API_getChannelData(slug,currentPage));
    setChannelData(res.data.results.results)
    setLoading(false)
  }, [slug]);
  return (
    <Fragment>
      <div className="hero_section p-inline">
        <h1 className="f-42 bold">
          {t('STEM-EY Digital Library')}
        </h1>
      </div>
      <div className="channel_tabs">

      <Tabs
        defaultActiveKey={tabs[0].eventKey}
        id="uncontrolled-tab-example"
        className="mb-3 shadow"
        onSelect={(eventKey)=>{
          setType(eventKey)
          setLoading(true)
          navigate(`/channel/${eventKey}`)

        }}
        activeKey={slug}
        >
          {tabs.map((item, index)=>(
              <Tab eventKey={item.eventKey} title={lang=="en"?item.title:item.title_arabic} >
              {loading ?
              <div className="loading">
                <ReactLoading type={"spin"} color={"#43919e"} height={500} width={100} />
              </div>
              :
                <ChannelTabs item={item} index={index} slug={slug} channelData={channelData}/>
              }
          </Tab>
          ))}


      </Tabs>
      {/* {slug=="weekly_together" &&      <Pagination numOfPages={2}/>} */}


      </div>
    </Fragment>
  );
};
export default Channel;
