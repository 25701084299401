import React, { Fragment, useEffect } from "react";
import classes from "./index.module.scss";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { t } from "i18next";
import VideoCard from "../../../../components/VideoCard";
import ChannelCard from "../../../../components/ChannelCard";
import { useSelector } from "react-redux";




const ChannelTabs = ({ slug, channelData, item}) => {
  const lang = useSelector((state) => state.settingsReducer.lang);

  useEffect(() => {}, []);
  return (


  <div className="video-wrapper p-inline mb-4">
    {slug=='weekly_together'?
    <>

<h2 className="f-23 bold">{t("Featured Videos")}</h2>

        {channelData?.camp?.map((item, index)=>(
          <div key={`module-channel-${index}`}>
          <div className="d-flex flex-wrap">
              <ChannelCard item={item} type="Video"  wrapper={t('Module')} order={item.order -1} key={`week-together-${index}`}/>
          </div>
        </div>))}
        {channelData?.not_camp?.map((item, index)=>(
          <div key={`module-channel-${index}`}>
          <h2 className="f-23 bold">{t("Module")} {index+1}: {lang=="en"?item.title:item.title_arabic}</h2>
          <div className="d-flex flex-wrap">

            {item?.weekly_resources?.map((video, index)=>(
              <ChannelCard item={video} type="Video" wrapper={t('Week')} order={video.order} key={`week-together-${index}`}/>
            ))}
          </div>
        </div>))}
        </>

        :
        <>
        <h2 className="f-23 bold">{lang=="en"?item.title:item.title_arabic}</h2>
        <div className="d-flex flex-wrap">
          {channelData?.map((item, index)=>
           <ChannelCard item={item} type="Video" wrapper={slug=="pd_videos"?t('Video'):t('Module')} order={slug=='fictional_story'?index:slug=="pd_videos"?'':item.order-1} key={`channel-video-${index}`} />
          )}
          </div>
        </>
    }
  </div>

  );
};
export default ChannelTabs;
