import React, { useEffect, useState } from "react";
import images from "../../../resources/images";
import { useTranslation } from "react-i18next";
import classes from "./SectionLanding.module.scss";
import { useSelector } from "react-redux";

import FilterDropdown from "../components/FilterDropdown";
const SectionLanding = () => {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);


	return (
		<section className={`${classes.landing}`}>

			<div className={`${classes.left} p-inline`}>
				<h1 className={`f-42 bold`}>{t("Egyptian STEM for Early Years Program")}</h1>
				<p className="f-18 light">{t("Unlock a whole new teaching and learning experience with STEM practices built to support the developmental needs of children")}</p>

				<FilterDropdown/>
			</div>

			<div className={`${classes.right}`}>
				<img src={images.nurseryChildren} alt="nursery children"/>
			</div>


		</section>
	);
};
export default SectionLanding;
