import React from "react";
import Button from "../../../components/Buttons";
import ChannelCard from "../../../components/ChannelCard";
import classes from "./SectionFooter.module.scss";
import { t } from "i18next";
import images from "../../../resources/images";

const SectionFooter = () => {
	const items = [
		{
			type: "Channel",
			thumbnail: images.weeklyTogether,
			title: "Facilitator Weekly Together Practices",
			title_arabic: "ممارسات الميسر الأسبوعية معًا",
			route:'weekly_together'
		},
		{
			type: "Channel",
			thumbnail: images.parentActivities,
			title: "Parents/Families Activities",
			title_arabic: "أنشطة ولي الأمر",
			route:'parent_activities'
		},

		{
			type: "Channel",
			thumbnail: images.fictional,
			title: "Animated Fictional Stories",
			title_arabic: "قصص خيالية متحركة",
			route:'fictional_story'


		},
		{
			type: "Channel",
			thumbnail:images.weeklyTogether,
			title: "Facilitator PD Plans",
			title_arabic: "خطط التنمية الشخصية للميسرة",
			route:'pd_videos'

		},
	];
	return (
		<section className={`${classes.footer} p-inline p-block`}>

			<div className={`${classes.footer_text}`}>
				<h1 className={`f-32 bold mb-3`}>{t("STEM-EY Digital Library")}</h1>
				<p className={`f-18 meduim`}>
					{t("STEM programming encourages and supports children in making a meaning out of their curiosity, exploration and experiences")}
				</p>
			</div>

			<div className={"d-flex flex-wrap"}>
				{items.map((item, index) => (
					<ChannelCard item={item} key={index}/>
				))}
			</div>
		</section>
	);
};
export default SectionFooter;
