import React, { useEffect, useState } from "react";
import images from "../../../resources/images";
import { useTranslation } from "react-i18next";
import classes from "./Circle.module.scss";
import { useSelector } from "react-redux";

const Circle = ({title, desc, index}) => {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);

	return (
        <div className={`${classes.circle} ${index==0?classes.first:''} ${index==4?classes.last:''}`}>
            {index==0?
            <>
            <h3 className="f-48 bold">{title}</h3>
            <p className="f-20 bold">{desc}</p>
            <span className="f-14 regular">{t("STEM Themes")}</span>
            </>
           :
           <>
             <h3 className="f-16 bold">{title}</h3>
            <p className="f-14 meduim">{desc}</p>
           </>

            }

        </div>

	);
};
export default Circle;
