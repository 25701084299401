import React, { Fragment, useEffect, useState } from "react";
import CustomHeroSection from "../../components/CustomHeroSection";
import images from "../../resources/images";
import classes from "./index.module.scss";
import { endpoints, baseUrl } from "../../network/url";
import { API_getParentData } from "../../store/actions/parentActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import VideoCard from "../../components/VideoCard";
import WeekAccordion from "../../components/WeekAccordion";
import FeaturedComponent from "../../components/FeaturedComponent";


const Parent = () => {
	const {slug}=useParams();
	const [module, setModule]=useState(slug)
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState("");
	const [video, setVideo] = useState();

	const lang = useSelector((state) => state.settingsReducer.lang);
	const weeklyResources = useSelector((state) => state.facilitatorReducer.weeklyResources);

	useEffect(async () => {
		setLoading(true);
		const res = await dispatch(API_getParentData(module));
		setData(res.data.data);
		setVideo(res.data.data.facilitator_fictional_story);
		setLoading(false);

		// console.log("Parent --->", res.data.data);
	}, [slug]);

	return (
		<React.Fragment>
			{loading ? (
				<div className={classes.loading}>
					<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
				</div>
			) : (
				<React.Fragment>
					{video && (
						<CustomHeroSection
						title={data.title}
						slug={slug}
							setModule={setModule}
							bg={images.mainPurbleBg}
							isEqual={false}
							isParent={true}
							sideRight={
								<Fragment>
									<div className={classes.badges}>
										<p className="f-18 meduim">{t(`Module`)} {data.order - 1}</p>
										<span className="badge">
											<images.calendar1 />
											<p className="f-14 semi_bold">{t("4 weeks")}</p>
										</span>
									</div>
									<h1 className={`f-42 bold`}>{lang == "en" ? data.title : data.title_arabic}</h1>
									{/* <p className="f-18 light">{lang == "en" ? data.description : data.description_arabic}</p> */}
									<p className="f-18 light mb-3">{t("Explore how parents and families can engage with their children through fun and easy Together Practice activities at home. Activities are designed to provide a bridge between content taught in the classroom and experiences at home. Learn about the activities designed to go with the Me and My Community Theme by watching the Parent and Family video.")}</p>
									<p className="f-23 semi_bold my-4">{t("Caregiver Resources Include")}</p>
									<ul>
									<li>
										<images.whiteCheckMark />
											<p className="f-18 regular mx-2">{t("Opportunities to engage in meaningful interactions between family members and children")}</p>
										</li>
										<li>
											<images.whiteCheckMark />
											<p className="f-18 regular mx-2">{t("5 simple Together Practice activities per week organized in a calendar, aligned with the weekly classroom theme")}</p>
										</li>
										<li>
											<images.whiteCheckMark />
											<p className="f-18 regular mx-2">{t("1 practice per a day")}</p>
										</li>
									</ul>
								</Fragment>
							}
							sideLeft={
							<VideoCard item={video} type="Video" inPlace={true} />
							}
						/>
					)}
					<section className="m-block p-inline">
						<h4 className="mb-5 f-32 bold text-center">{t("Theme Content")}</h4>
						{data.weeks && <WeekAccordion weeks={data?.weeks} weeklyResources={weeklyResources}  module={data.slug} isParent={true}/>}

					</section>
					<div className="p-inline m-bottom ">

						<FeaturedComponent image={images.bluePattren} icon={<images.checklistIcon />} btnText={t("Learn More about Caregiver Guidance")} navigation={true} route={'/caregiver'}>
							<h3 className="bold f-28">{t("How Can you Help Children Learn and Grow?")}</h3>
							<span className="d-inline-block">{t("Enjoy doing fun and easy activities every day with children chicklists")}</span>
						</FeaturedComponent>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
export default Parent;
