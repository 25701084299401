import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import classes from "./index.module.scss";
import { useTranslation } from "react-i18next";

const DefaultLayout = (props) => {
	const { t } = useTranslation();
	const [scroll, setScroll] = useState(true);
	const lang = useSelector((state) => state.settingsReducer.lang);
	const [width, setWidth] = React.useState(window.innerWidth);

	const updateWidthAndHeight = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		// console.log(lang);
	}, [lang]);
	// useEffect(() => {
	// 	window.addEventListener("scroll", () => {
	// 		setScroll(window.scrollY > 8);
	// 	});
	// }, [scroll]);
	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
			// console.log("window", window.innerWidth);
		});
		return () => window.removeEventListener("resize", setWidth(window.innerWidth));
	}, [width]);
	return (
		<React.Fragment>
			<Header scroll={scroll} width={width}></Header>
			<main>{props.children}</main>
			<Footer />
		</React.Fragment>
	);
};
export default DefaultLayout;
