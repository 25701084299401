import React, { useRef, useState } from "react";
import images from "../../../resources/images";
import ProgramLevelContant from "../components/ProgramLevelContent";
import ProgramLevelSubContant1 from "../components/ProgramLevelSubContant1";
import ProgramLevelSubContant2 from "../components/ProgramLevelSubContant2";
import ProgramLevelTab from "../components/ProgramLevelTab";
import classes from "./SectionProgramLevels.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SectionProgramLevels = () => {
	const [activeTabAtTab2, setActiveTabAtTab2] = useState("facilitator");
	const [activeTabAtTab3, setActiveTabAtTab3] = useState("facilitator");

	const [program, setProgram]=useState('facilitator')
	const lang = useSelector((state) => state.settingsReducer.lang);
	const { t } = useTranslation();
	const caregiverGuidanceRef = useRef();
	const observersRef = useRef();
	const explorersRef = useRef();
	const discoverRef = useRef();

	const getNavContainerClassName = () => (lang == "ar" ? "main-navContainer" : "main-navContainer");
	return (
		<section className={`${classes["program-levels"]} p-inline`}>
			<h1 className={`bold f-32`}>{t("STEM Program Developmental Levels")}</h1>
			<div className={`${getNavContainerClassName()} ${classes.main_navContainer} shadow`}>
			<img src={images.shape1} className={classes.shape} />
				<img src={images.shape4} className={classes.shape} />

<div className={`nav nav-pills px-0`} id="v-pills-tab" role="tablist" aria-orientation="vertical">
	<ProgramLevelTab
		className={`${classes["nav-link"]}`}
		initActivation={true}
		contentRef={caregiverGuidanceRef}
		title={t("Caregiver Guidance")}
		text={t("Age 0 - 24 Months")}
		img={images.child1}
	/>
	<ProgramLevelTab
		className={`${classes["nav-link"]}`}
		contentRef={observersRef}

		title={t("Observers")}
		text={t("Age 2 - 3 years")}
		img={images.child2}
	/>
	<ProgramLevelTab
		className={`${classes["nav-link"]}`}
		contentRef={explorersRef}
		title={t("Explorers")}
		text={t("Age 3 - 4 years")}
		img={images.child3}
	/>
	<ProgramLevelTab
		className={`${classes["nav-link"]}`}
		contentRef={discoverRef}
		title={t("Discover")}
		text={t("Age 4 - 5 years")}
		img={images.child4}


	/>
</div>
<div className="tab-content" id="v-pills-tabContent">
	<div
		ref={caregiverGuidanceRef}
		className="tab-pane fade show active"
		id="v-pills-home"
		role="tabpanel"
		aria-labelledby="v-pills-home-tab"
	>
		<ProgramLevelContant
			age={t("Age 0 - 24 Months")}
			title={t("Milestones Matter!")}
			desc={t("How your child plays, learns, speaks, acts, and moves offers important clues about his or her development.")}
			route={"caregiver"}
			img={images.child1}

		>
			<ProgramLevelSubContant1 />
		</ProgramLevelContant>
	</div>
	<div ref={observersRef} className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
		<ProgramLevelContant
			age={t("Age 2 - 3 years")}
			title={t("Ignite your Observers Curiosity!")}
			desc={t(
				"Engage with a variety of STEM Together Practices designed to foster a cultural shift around STEM teaching and learning."
			)}
			items={[t("12 Theme per year"), t("8 Together Practicies per week"), t('4 Nonfiction stories per Theme'),t('1 Fictional story per Theme')]}
			route={activeTabAtTab2}
			img={images.child2}
			hideStart={true}


		>
			<ProgramLevelSubContant2 setActiveTab={setActiveTabAtTab2} />
		</ProgramLevelContant>
	</div>
	<div ref={explorersRef} className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
		<ProgramLevelContant
			age={t("Age 3 - 4 years")}
			title={t("Unlock Explorers Potentials!")}
			desc={t(
				"Take Talking, Creating and Solving Together along with other STEM together practices to higher developmental level as your explorers start demonstrating better observation, prediction, and collaboration skills."
			)}
			items={[t("12 Theme per year"), t("8 Together Practicies per week"),t('4 Nonfiction stories per Theme'), t('1 Fictional story per Theme') ]}
			route={activeTabAtTab3}
			img={images.child3}
			hideStart={true}


		>
			<ProgramLevelSubContant2 setActiveTab={setActiveTabAtTab3} />
		</ProgramLevelContant>
	</div>
	<div ref={discoverRef} className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
		<ProgramLevelContant
			age={t("Age 4 - 5 years")}
			title={t("Celebrate your Children KG Readiness with Edu. 2.0!")}
			desc={t(
				"As your Children grow older and ready for formal learning, pave a smooth transition to schools by implementing KG1/KG2 Edu. 2.0 Multidisciplinary program in your nurseries"
			)}
			items={[t("4 Multidisciplinary Themes per Year"), t("4 Chapters per Theme"), t("6 to 10 Lessons per Chapter")]}
			hideSub={true}
			hideStart={true}
			img={images.child4}
		>
			<div className="py-4 h-100 d-flex flex-column justify-content-center align-items-start ">
				<h3 className={`f-20`}>{t("Dive deep into Edu. 2.0 KG1/KG2 Resources")}</h3>
				<div className={classes.link_wrapper}>
					<a
						className={`${classes.link_card} p-2`}
						target="_blank"
						href="https://discoveryeducation.ekb.eg/curriculum/primary/#/multidisciplinary/kg-1/"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="54.439"
							height="62.217"
							viewBox="0 0 54.439 62.217"
							className={`d-none d-md-block p-2`}
						>
							<g id="Group_5107" data-name="Group 5107" transform="translate(-781.437 -2788.227)">
								<g id="pdf" transform="translate(781.437 2788.227)">
									<path
										id="Path_5302"
										data-name="Path 5302"
										d="M23.217,0H56.256L73.768,17.444V58.328a3.889,3.889,0,0,1-3.888,3.888H23.217a3.889,3.889,0,0,1-3.888-3.888V3.888A3.889,3.889,0,0,1,23.217,0Z"
										transform="translate(-19.329 0)"
										fill="#f0f0f0"
									/>
									<path
										id="Path_5303"
										data-name="Path 5303"
										d="M220.4,17.652H206.845a3.89,3.89,0,0,1-3.888-3.888V.193Z"
										transform="translate(-166.016 -0.154)"
										fill="#c5c5c2"
									/>
								</g>
								<g id="svgexport-6_92_" data-name="svgexport-6 (92)" transform="translate(790.649 2787.627)">
									<g id="Group_5089" data-name="Group 5089" transform="translate(0 19.45)">
										<path
											id="Path_6952"
											data-name="Path 6952"
											d="M413,109.593a2.238,2.238,0,0,1,.623.092l3.728-4.57a.686.686,0,0,1,.969-.1.668.668,0,0,1,.085.938l-3.686,4.52a2.111,2.111,0,0,1-.849,3.163h14.463a.961.961,0,0,0,.961-.961V100.511a.961.961,0,0,0-.961-.961H409.961a.961.961,0,0,0-.961.961V108.5l.869,1.1H413Z"
											transform="translate(-393.281 -96.471)"
											fill="#03ca8e"
										/>
										<circle
											id="Ellipse_614"
											data-name="Ellipse 614"
											cx="3.936"
											cy="3.936"
											r="3.936"
											transform="translate(3.801 0)"
											fill="#176eb5"
										/>
										<g id="Group_5088" data-name="Group 5088" transform="translate(0 9.132)">
											<path
												id="Path_6953"
												data-name="Path 6953"
												d="M11.772,281.475a.961.961,0,0,1-.961.961H9.2a.961.961,0,0,1-.961-.961v-8.056a.515.515,0,0,0-.515-.515h0a.515.515,0,0,0-.515.515v8.017a1,1,0,0,1-1,1H4.627a.961.961,0,0,1-.961-.961l-.008-19.447a.476.476,0,0,0-.477-.477h0a.477.477,0,0,0-.477.477v7.479a.961.961,0,0,1-.961.961H.961A.961.961,0,0,1,0,269.506V259.252a2.2,2.2,0,0,1,2.2-2.2h8.751a3.083,3.083,0,0,1,2.41,1.165l2.352,2.967.5.626s2.229,0,3.513,0a.961.961,0,0,1,.961.961v.765a.961.961,0,0,1-.961.961H15.566a1.339,1.339,0,0,1-1.053-.511l-1.753-2.252a.479.479,0,0,0-.377-.185h-.119a.476.476,0,0,0-.477.477Z"
												transform="translate(0 -257.05)"
												fill="#176eb5"
											/>
										</g>
									</g>
								</g>
							</g>
						</svg>

						<span className="">{t("Teacher's Guide")}</span>
					</a>
					<a
						className={`${classes.link_card} p-2`}
						target="_blank"
						href="https://discoveryeducation.ekb.eg/curriculum/primary/#/multidisciplinary/kg-1/"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="54.439"
							height="62.217"
							viewBox="0 0 54.439 62.217"
							className={`d-none d-md-block p-2`}
						>
							<g id="Group_5110" data-name="Group 5110" transform="translate(-1031.437 -2788.227)">
								<g id="pdf" transform="translate(1031.437 2788.227)">
									<path
										id="Path_5302"
										data-name="Path 5302"
										d="M23.217,0H56.256L73.768,17.444V58.328a3.889,3.889,0,0,1-3.888,3.888H23.217a3.889,3.889,0,0,1-3.888-3.888V3.888A3.889,3.889,0,0,1,23.217,0Z"
										transform="translate(-19.329 0)"
										fill="#f0f0f0"
									/>
									<path
										id="Path_5303"
										data-name="Path 5303"
										d="M220.4,17.652H206.845a3.89,3.89,0,0,1-3.888-3.888V.193Z"
										transform="translate(-166.016 -0.154)"
										fill="#c5c5c2"
									/>
								</g>
								<g id="svgexport-6_91_" data-name="svgexport-6 (91)" transform="translate(1042.328 2807.077)">
									<g id="Group_5084" data-name="Group 5084" transform="translate(0 0)">
										<g id="Group_5077" data-name="Group 5077" transform="translate(8.037)">
											<path
												id="Path_6944"
												data-name="Path 6944"
												d="M186.205,102.551V105.1a5.1,5.1,0,1,1-10.205,0v-2.551h.975A4.615,4.615,0,0,0,181.1,100a4.615,4.615,0,0,0,4.128,2.551Z"
												transform="translate(-172.811 -93.622)"
												fill="#176eb5"
											/>
											<g id="Group_5076" data-name="Group 5076">
												<path
													id="Path_6946"
													data-name="Path 6946"
													d="M142.584,8.292A8.292,8.292,0,1,0,126,8.371a5.266,5.266,0,0,0,1.284,3.446c-.005-.111-.008-.223-.008-.336V8.93a1.914,1.914,0,0,1,1.913-1.914h.974a2.687,2.687,0,0,0,2.417-1.494,1.914,1.914,0,0,1,3.423,0,2.687,2.687,0,0,0,2.417,1.494h.974a1.914,1.914,0,0,1,1.914,1.914v2.551c0,.113,0,.225-.008.336a5.266,5.266,0,0,0,1.284-3.446Z"
													transform="translate(-126 0)"
													fill="#176eb5"
												/>
											</g>
										</g>
										<g id="Group_5083" data-name="Group 5083" transform="translate(0 3.671)">
											<g id="Group_5079" data-name="Group 5079">
												<path
													id="Path_6947"
													data-name="Path 6947"
													d="M31.466,331.893l-.213-1.207a2.232,2.232,0,1,1,4.4-.775l.213,1.207a2.232,2.232,0,1,1-4.4.775Z"
													transform="translate(-29.228 -310.811)"
													fill="#03ca8e"
												/>
												<g id="Group_5078" data-name="Group 5078">
													<path
														id="Path_6948"
														data-name="Path 6948"
														d="M4.227,72.891c.08,0,.16,0,.239.008L1.9,58.341a.957.957,0,1,0-1.884.332L2.581,73.232a4.14,4.14,0,0,1,1.645-.34Z"
														transform="translate(0 -57.55)"
														fill="#03ca8e"
													/>
												</g>
											</g>
											<g id="Group_5080" data-name="Group 5080" transform="translate(6.397 14.723)">
												<path
													id="Path_6949"
													data-name="Path 6949"
													d="M116.1,288.377h-1.084v.957a4.784,4.784,0,0,1-9.567,0v-.957h-2.36a7.181,7.181,0,0,0-2.787.56l.133.757a4.138,4.138,0,0,1,1.776,2.727l.213,1.207a4.139,4.139,0,0,1,.051,1.039h5.206a5.426,5.426,0,0,1,2.551.642,5.426,5.426,0,0,1,2.551-.642h10.466A7.209,7.209,0,0,0,116.1,288.377Z"
													transform="translate(-100.298 -288.377)"
													fill="#176eb5"
												/>
											</g>
											<g id="Group_5081" data-name="Group 5081" transform="translate(0 22.927)">
												<path
													id="Path_6950"
													data-name="Path 6950"
													d="M31.7,419.87H30.68v-1.914a.957.957,0,0,0-.957-.957H18.88a3.5,3.5,0,0,0-2.551,1.1,3.5,3.5,0,0,0-2.551-1.1H7.927a4.145,4.145,0,0,1-5.95,1.107v1.764H.957a.957.957,0,1,0,0,1.913H13.249a3.377,3.377,0,0,1,2.4,1,.956.956,0,0,0,1.353,0,3.377,3.377,0,0,1,2.4-1H31.7a.957.957,0,1,0,0-1.913Z"
													transform="translate(0 -417)"
													fill="#03ca8e"
												/>
											</g>
											<g id="Group_5082" data-name="Group 5082" transform="translate(13.458 14.212)">
												<path
													id="Path_6951"
													data-name="Path 6951"
													d="M211,280.368v1.468a2.87,2.87,0,1,0,5.74,0v-1.468a7.01,7.01,0,0,1-5.74,0Z"
													transform="translate(-211 -280.368)"
													fill="#176eb5"
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</svg>
						<span className="">{t("Student's Guide")}</span>
					</a>
				</div>
			</div>
		</ProgramLevelContant>
	</div>
</div>
</div>

		</section>
	);
};
export default SectionProgramLevels;
