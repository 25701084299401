import axios from "axios";
import { constants } from "../constants";
import { baseUrl, endpoints } from "../../network/url";

export const API_postGoogleAuthData = (token) => {
  return async () => {
    try {
      const response = await axios.post(`${baseUrl}/auth/google_oauth`, {
        token,
      });
      console.log("API_postGoogleAuthData", response);

      return response;
    } catch (error) {
      console.error("API_postGoogleAuthData", error.response);
      return error.response;
    }
  };
};
export const setToken = (token) => {
  return {
    type: constants.SET_TOKEN,
    payload: token,
  };
};
export const setName = (token) => {
  return {
    type: constants.SET_NAME,
    payload: token,
  };
};
