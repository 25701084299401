import React, { useState } from "react";
import classes from "./index.module.scss";
import images from "../../resources/images";
import { Link } from "react-router-dom";
import VideoModal from "../VideoModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const ChannelCard = ({item, type, wrapper, order}) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
    const { t } = useTranslation();

	return (
        type!='Video'?
		<Link className={`${classes.channel} p-0`} to={`/channel/`+item.route}>
            <div className={classes.channel__top}>
            <img src={lang=="en"?item.thumbnail:item.thumbnail_arabic?item.thumbnail_arabic:item.thumbnail} />
                <div className="overlay"/>
            </div>
            <div className={classes.channel__footer}>
                <span className="f-14 semi_bold">{t("Channel")}</span>
                <h3 className="f-18">{lang=="en"?item.title:item.title_arabic}</h3>
            </div>
        </Link>
        :
        <>
        <a className={`${classes.channel} p-0 shadow`} onClick={handleShow}>

            <div className={classes.channel__top}>
            <span className={`${classes.play} border-0`}><images.videoIcon /></span>

            <img src={lang=="en"?item.thumbnail:item.thumbnail_arabic?item.thumbnail_arabic:item.thumbnail} />
                <div className="overlay"/>
            </div>
            <div className={classes.channel__footer}>
                <span className="f-14 semi_bold">{wrapper} {order}</span>
                <h3 className="f-18">{lang=="en"?item.title:item.title_arabic}</h3>
            </div>
        </a>
        <VideoModal
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            src={lang === "en" ? item.media : item.media_arabic }
        />

</>
	);
};

export default ChannelCard;
