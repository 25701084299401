import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAJInDtn-KVeJwGcc79AdkPILgu60Oo1GY",
  authDomain: "plj-stem-ey.firebaseapp.com",
  projectId: "plj-stem-ey",
  storageBucket: "plj-stem-ey.appspot.com",
  messagingSenderId: "969246571114",
  appId: "1:969246571114:web:140e9ac3acc9cff5b8e813",
  measurementId: "G-2R0DKPLE06",
};

const app = initializeApp(firebaseConfig);

export const authinticate = getAuth(app);
