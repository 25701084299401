import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
import { constants } from "../constants";



export const API_getFacilitatorData = (module) => {
	return async () => {
		try {
			const response = await axios.get(`${baseUrl}/facilitator/${module}`);
			return response;
		} catch (error) {
			return error.response;
		}
	};
};
export const API_getFacilitatorWeeks = (module, week) => {
	return async () => {
		try {
			const response = await axios.get(`${baseUrl}/facilitator/${module}/week/${week}`);
			return response;
		} catch (error) {
			return error.response;
		}
	};
};
export const API_getModuleNames = () => {
	return async (dispatch) => {
		try {
			const response = await axios.get(`${baseUrl}/facilitator/module_names`);
			dispatch(setFGModules(response.data.facilitator_themes))
			dispatch(setPFGModules(response.data.parent_themes))
			return response;
		} catch (error) {
			return error.response;
		}
	};
};
export const setWeeklyResources = (weeklyResources) => {
	return {
		type: constants.SET_WEEKLY_RESOURCES,
		payload: weeklyResources,
	};
};

const setFGModules = (modules) => {
    return {
        type: constants.SET_FG_MODULES,
        payload: modules,
    }
}
const setPFGModules = (modules) => {
    return {
        type: constants.SET_PFG_MODULES,
        payload: modules,
    }
}