import React, { Fragment, useState, useEffect } from "react";
import CustomHeroSection from "../../components/CustomHeroSection";
import VideoCard from "../../components/VideoCard";
import images from "../../resources/images";
import { Row, Col } from "react-bootstrap";
import { API_getFacilitatorData, setWeeklyResources } from "../../store/actions/facilitatorActions";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

import CustomeResourceCard from "../../components/CustomeResourceCard";


import classes from "./index.module.scss";
import FacilitatorAccordion from "../../components/WeekAccordion";
import { useDispatch, useSelector } from "react-redux";
import FeaturedComponent from "../../components/FeaturedComponent";
import {
	BrowserRouter as Router,
	useParams,
	useNavigate

  } from "react-router-dom";
import WeekAccordion from "../../components/WeekAccordion";

const Facilitator = () => {
	const {slug}=useParams();
	const [module, setModule]=useState(slug)

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState("");

	const lang = useSelector((state) => state.settingsReducer.lang);
	const weeklyResources = useSelector((state) => state.facilitatorReducer.weeklyResources);



	useEffect(async () => {
		setLoading(true);
		const res = await dispatch(API_getFacilitatorData(module));
		const weeklyResources = res.data.data.weeks.map((week) => {
			return week.weekly_resources;
		});
		dispatch(setWeeklyResources(weeklyResources));
		if (res.data.data.fictional_story) {
			res.data.data.fictional_story.thumbnail =
				"https://plj-prod-backup.s3.us-east-2.amazonaws.com/stemey/Screen+Shot+2022-03-05+at+3.18.25+AM.png";
		}
		setData(res.data.data);
		setLoading(false);

	}, [slug]);

	return (

		<React.Fragment>
			{loading ? (
				<div className={classes.loading}>
					<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
				</div>

			) : (
				<React.Fragment>
					<CustomHeroSection
					title={data.title}
					slug={slug}
						setModule={setModule}
						bg={images.mainPurbleBg}

						sideRight={
							<Fragment>
								<div className={classes.badges}>
									<p className="f-18 meduim">{t(`Module`)} {data.order - 1}</p>
									<span className="badge">
										<images.calendar1 />
										<p className="f-14 semi_bold">{t("4 weeks")}</p>
									</span>
								</div>
								<h1 className={`f-42 bold my-4`}>{lang == "en" ? data.title : data.title_arabic}</h1>
								<p className="f-18 light">{lang == "en" ? data.description : data.description_arabic}</p>
							</Fragment>
						}
						sideLeft={<VideoCard item={data?.fictional_story} type="Video" inPlace={true} />}
						bottomColumn={
							<div className={classes.column2}>
								<Row>
									<Col lg={6}>
										<ul>
											<h3 className={`bold f-23`}>{t("Facilitator Guidance")}</h3>
											<li>
												<images.checkmarkPurple />
												<span>{t("8 weekly together practices.")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("Suggested daily schedule, features 3 STEM blocks with Together Activities")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("Step-by-Step Differentiated instruction for Observers and Explorers")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("Supportive practices support nonverbal and newly verbal learners")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("Videos for facilitators support specific activities and teaching strategies")}</span>
											</li>
										</ul>
									</Col>
									<Col lg={6}>
										<ul>
											<h3 className={`bold f-23`}>{t("Reading Guidance")}</h3>
											<li>
												<images.checkmarkPurple />
												<span>{t("Multiple, daily opportunities to develop literacy skills through oral language development prompts")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("4 nonfictional stories (1 per week).")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("1 fictional story (1 per theme).")}</span>
											</li>
											<li>
												<images.checkmarkPurple />
												<span>{t("Oral Language Development prompts support daily literacy instruction")}</span>
											</li>
										</ul>
									</Col>
								</Row>
							</div>
						}
					/>
					<section className="m-block p-inline">
						<h4 className="mb-5 f-32 bold text-center">{t("Theme Content")}</h4>
						{data.weeks && <WeekAccordion weeks={data?.weeks} weeklyResources={weeklyResources}  module={data.slug} />}
					</section>
					<div className="p-inline m-bottom ">
						<FeaturedComponent image={images.bluePattren} icon={<images.checklistIcon />} btnText={t("Learn More about Caregiver Guidance")} navigation={true} route={'/caregiver'}>
							<h3 className="bold f-28">{t("How Can you Help Children Learn and Grow?")}</h3>
							<span className="d-inline-block">{t("Enjoy doing fun and easy activities every day with children chicklists")}</span>
						</FeaturedComponent>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
export default Facilitator;
