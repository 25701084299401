import axios from "axios";
import { baseUrl, endpoints } from "../../network/url";
export const API_getChannelData = (type, page) => {
    return async () => {
      try {
        const response = await axios.post(`${baseUrl}/channel/media?page=${page}`,{
          'channel_type':type
        });
        return response;
      } catch (error) {
        console.error("API_getChannelData", error.response);
        return error.response;
      }
    };
  };