export const translation_ar = {
	"Program design core elements": "العناصر الأساسية لتصميم البرنامج",
	"Collaborative relationships between parents/families with their children & classroom educators.":
		"العلاقات التعاونية بين الآباء / العائلات مع أطفالهم ومعلمي الصف.",
	"Developmental benchmarks guide & indicators for what children should know and be able to do, with adult support.":
		"دليل المعايير التنموية والمؤشرات لما يجب أن يعرفه الأطفال وأن يكونوا قادرين على القيام به ، بدعم من الكبار.",
	"The interactions between adults and children and includes 8 key together practices.":
		"التفاعلات بين البالغين والأطفال وتتضمن 8 ممارسات رئيسية معًا.",
	"Language development tools to support children in developing communication skills.": "أدوات تطوير اللغة لدعم الأطفال في تنمية مهارات الاتصال.",
	"Egyptian STEM Early Years Program Design": "برنامج STEM للسنوات المبكرة",
	"Central to the design are the themes, which are based on a variety of STEM-based topics and will be presented in 12 modules throughout the year (one per month).":
		"محور التصميم هو الموضوعات ، التي تستند إلى مجموعة متنوعة من الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات وسيتم تقديمها في 12 وحدة على مدار العام (واحدة في الشهر).",
	"Together Practices": "معا الممارسات",
	"The interactions between adults & children": "التفاعلات بين الكبار والأطفال",
	"Reading Guide": "دليل القراءة",
	"Oral Language development tools": "أدوات تطوير اللغة الشفهية",
	"Parent Family Guide": "دليل عائلة الوالدين",
	"Domains and Supportive Practices": "المجالات والممارسات الداعمة",
	"Collaborative relation building guide": "دليل بناء العلاقات التعاونية",
	"Developmental benchmarks guide": "دليل معايير التنمية",
	"STEM Program Developmental Levels": "المستويات التنموية لبرنامج STEM",
	"Caregiver Resources": "دليل مقدم الرعاية",
	"Facilitator Resources": "دليل الميسرة",
	"Parent Family Resources": "دليل ولي الأمر",
	"Egyptian STEM for Early Years Program": "برنامج Egyptian STEM للسنوات المبكرة",
	"Helping children from 0 - 4 years old to explore, learn, and grow in ways that prepare them for future success early.":
		"مساعدة الأطفال من سن 0 إلى 4 سنوات على الاستكشاف والتعلم والنمو بطرق تعدهم للنجاح في المستقبل مبكرًا.",
	"The design of the program is centered on 2 core elements": "يتمحور تصميم البرنامج على عنصرين أساسيين",
	"Thematic Instruction": "تعليم مواضيعي",
	Collaboraion: "تعاون",
	"Thematic instruction which are based on a variety of STEM-based topics.":
		"التعليمات الموضوعية التي تستند إلى مجموعة متنوعة من الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات.",
	"Fostering of collaborative relationships among classroom educators & parents/families.":
		"تعزيز العلاقات التعاونية بين المعلمين في الفصول الدراسية وأولياء الأمور / الأسر.",
	"From the day they are born, children are natural explorers, scientists, and learners. Parents and caregivers confirm that children love to experiment, observe, and explore. STEM, which is an international acronym meaning science, technology, engineering, and math, is a perfect fit for the curiosity and imagination of children.":
		"من يوم ولادتهم ، يعتبر الأطفال مستكشفين طبيعيين وعلماء ومتعلمين. يؤكد الآباء ومقدمو الرعاية أن الأطفال يحبون التجربة والمراقبة والاستكشاف. STEM ، وهو اختصار دولي يعني العلوم والتكنولوجيا والهندسة والرياضيات ، مناسب تمامًا لفضول الأطفال وخيالهم. ",
	"STEM-Based Themes": "الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات",
	"Structured Play & Hands-on Learning": "اللعب المنظم والتعلم العملي",
	"STEM Together Practices": "ممارسات STEM معًا",
	"Unlock a whole new teaching and learning experience with STEM practices built to support the developmental needs of children":"أطلق العنان لتجربة تعليم وتعلم جديدة تمامًا مع ممارسات STEM المصممة لاحتياجات أطفالك التنموية",
	"Sponsored By": "برعاية",
	"Useful Links": "روابط مفيدة",
	"8 weekly together practices.": "8 ممارسات أسبوعية معًا.",
	"Facilitator Guidance": "دليل الميسرة",
	"Reading Guidance": "دليل القراءة",
	"3 Together activities/Practices per day.": "3 أنشطة / ممارسات معًا يوميًا.",
	"Suggested weekly activities": "النشاط الأسبوعي المقترح",
	"Separate instructions for observers and explorers.": "تعليمات منفصلة للمراقبين والمستكشفين.",
	"4 nonfictional stories (1 per week).": "4 قصص غير خيالية (1 في الأسبوع).",
	"1 fictional story (1 per theme).": "1 قصة خيالية (1 لكل وحدة).",
	Week: "أسبوع",
	"Central to the design are the themes, which are based on a variety of STEM-Based topics and will be presented in 12 modules throughout the year (one per month).":
		"محور التصميم هو الموضوعات ، التي تستند إلى مجموعة متنوعة من الموضوعات المستندة إلى العلوم والتكنولوجيا والهندسة والرياضيات وسيتم تقديمها في 12 وحدة على مدار العام (واحدة في الشهر).",
	"The first years in a child’s life are so important. Tracking how little ones play, learn, speak, act and move can help you support their development and act quickly in the event of a delay.":
		"السنوات الأولى في حياة الطفل مهمة للغاية. يمكن أن يساعدك تتبع كيف يلعب الأطفال ويتعلمون ويتحدثون ويتصرفون ويتحركون في دعم تطورهم والتصرف بسرعة في حالة التأخير.",
	"Interested in Saving your Child Checklist? Sign in with Google!": "هل أنت مهتم بحفظ قائمة التحقق الخاصة بطفلك؟ الدخول مع جوجل!",

	"Caregiver Guidance": "دليل مقدمة الرعاية",
	Observers: "الملاحظون",
	Explorers: "المستكشفون",
	Discover: "إكتشف",
	"Let's Start": "إبدأ الآن",

	"Take a deep dive into resources": "تعمق في الموارد",

	"Track your child’s developmental progress by looking for developmental milestones using an interactive, illustrated checklist.":
		"تتبع التقدم النمائي لطفلك من خلال البحث عن المعالم التنموية باستخدام قائمة مرجعية تفاعلية مصورة.",

	"Help your baby learn and grow by applying what you can do with them at his developmental milestone. Talk, read, sing, and play together every day.":
		"ساعد طفلك على التعلم والنمو من خلال تطبيق ما يمكنك فعله معهم في مرحلة نموه. تحدثوا واقرأوا وغنوا والعبوا معًا كل يوم.",

	"Ignite your Observers Curiosity!": "أشعلوا فضول مراقبيك!",

	"Engage with a variety of STEM Together Practices designed to foster a cultural shift around STEM teaching and learning.":
		"الانخراط في مجموعة متنوعة من ممارسات STEM معًا المصممة لتعزيز تحول ثقافي حول تعليم وتعلم العلوم والتكنولوجيا والهندسة والرياضيات.",

	"12 Theme per year": "١٢ وحدة في السنة ",
	"1 Module per month": "١ وحدة في الشهر",
	"8 Together Practicies per week": " ٨ ممارسات معا إسبوعيا ",

	"Milestones Matter!": "معالم مهمة!",
	"How your child plays, learns, speaks, acts, and moves offers important clues about his or her development.":
		"تقدم الطريقة التي يلعب بها طفلك ويتعلم ويتحدث ويتصرف ويتحرك أدلة مهمة حول تطوره.",

	Facilitator: "الميسر","facilitator":"الميسر",
	"Facilitator Guidance": "إرشادات الميسّر",
	"Empowering facilitators with": "تمكين الميسرين بـ",
	"Suggested weekly activity grid.": "شبكة النشاط الأسبوعية المقترحة.",
	"Simple detailed instructions per STEM Together activity.": "تعليمات مفصلة بسيطة لكل نشاط STEM Together.",

	"'Reading Guidance": "إرشادات القراءة",
	"Providing Children with": "تزويد الأطفال",
	"Excellent opportunity to develop literacy skills through the Oral language development prompts.":
		"فرصة ممتازة لتطوير مهارات القراءة والكتابة من خلال مطالبات تطوير اللغة الشفهية.",
	"4 non-Fictional stories per Module.": ".٤ قصص غير خيالية لكل وحدة",
	"1 Fictional story per Module.": "١ قصة خيالية لكل وحدة",

	"Parent/Family": "الوالد / الأسرة",
	"Parent/Family Guidance": "إرشاد الوالدين / الأسرة",
	"Providing Families with": "تزويد العائلات",
	"Meaningful interactions between them and their children.": "تفاعلات هادفة بينهم وبين أطفالهم.",
	"5 family Together Practices per week (1 Together practice per day).": "5 تمارين عائلية معًا في الأسبوع (ممارسة واحدة معًا يوميًا).",

	"Unlock Explorers Potentials!": "فتح إمكانات المستكشفين!",
	"Take Talking, Creating and Solving Together along with other STEM together practices to higher developmental level as your explorers start demonstrating better observation, prediction, and collaboration skills.":
		"خذ التحدث والإبداع والحل معًا جنبًا إلى جنب مع ممارسات STEM الأخرى معًا إلى مستوى تنموي أعلى حيث يبدأ المستكشفون في إظهار مهارات أفضل في الملاحظة والتنبؤ والتعاون.",

	"Celebrate your Children KG Readiness with Edu. 2.0!": "احتفل باستعداد أطفالك لمرحلة الروضة مع Edu. 2.0!",
	"As your Children grow older and ready for formal learning, pave a smooth transition to schools by implementing KG1/KG2 Edu. 2.0 Multidisciplinary program in your nurseries":
		"مع تقدم أطفالك في السن واستعدادهم للتعلم الرسمي ، قم بتمهيد الانتقال السلس للمدارس من خلال تطبيق KG1 / KG2 Edu. 2.0 برنامج متعدد التخصصات في حضاناتك",

	"Dive deep into Edu. 2.0 KG1/KG2 Resources": "تعمق في Edu. 2.0 KG1 / KG2 الموارد",

	"Teacher's Guide": "دليل المعلم",
	"Student's Guide": "دليل الطالب",

	"STEM-EY Digital Library": "مكتبة STEM-EY الرقمية",
	"STEM programming encourages and supports children in making a meaning out of their curiosity, exploration and experiences":
		"تشجع برمجة العلوم والتكنولوجيا والهندسة والرياضيات الأطفال وتدعمهم في صنع معنى من فضولهم واستكشافهم وخبراتهم",

	"Caregiver Resources Include": "تشمل موارد مقدم الرعاية",
	"What most babies do by this age checklist at different ages from 0-24 months.":
		"ما يفعله معظم الأطفال من خلال قائمة المراجعة العمرية هذه في مختلف الأعمار من 0-24 شهرًا.",
	"Suggested activities to enjoy with your with children": "أنشطة مقترحة للاستمتاع بها مع أطفالك",
	"Interested in Saving your Child Checklist? Sign in with Google!": "هل أنت مهتم بحفظ قائمة التحقق الخاصة بطفلك؟ الدخول مع جوجل!",
	"Sign in With Google": "تسجيل الدخول بحساب جوجل",
	"5 simple Together Practice activities per week organized in a calendar, aligned with the weekly classroom theme": "5 أنشطة بسيطة معًا تدرب على الأنشطة في الأسبوع منظمة في تقويم ، تتماشى مع موضوع الفصل الدراسي الأسبوعي",
	"1 practice per a day": "تمرين واحد في اليوم",
	"Theme Content": "محتويات الوحدة",

	"Activities to do with your class": "نشاط لتفعله مع فصلك",
	"Activities to do with your child": "نشاط لتفعله مع طفلك",

	"More Resources": "المزيد من الموارد",
	"For more resources you can explore full guidance and videos we provided for the module":
		"لمزيد من الموارد ، يمكنك استكشاف الإرشادات الكاملة ومقاطع الفيديو التي قدمناها للوحدة",

	"Your Baby": "طفلك",
	"Milestones matter! How your child plays, learns, speaks, acts, and moves offers important clues about his or her development. Check the milestones your child has reached by 18 months. Take this with you and talk with your child’s doctor at every well-child visit about the milestones your child has reached and what to expect next.":
		"المعالم مهمة! تقدم الطريقة التي يلعب بها طفلك ويتعلم ويتحدث ويتصرف ويتحرك أدلة مهمة حول تطوره. تحقق من المعالم التي وصل إليها طفلك قبل 18 شهرًا. خذ هذا معك وتحدث مع طبيب طفلك في كل زيارة لرعاية الطفل حول المعالم التي وصل إليها طفلك وما يمكن توقعه بعد ذلك.",
	"Help Your Baby Learn and Grow, What You Can Do for Your Child": "ساعد طفلك على التعلم والنمو ، ما يمكنك القيام به لطفلك",

	"Developmental Milestone Checklists": "مراحل نمو الأطفال",
	"Select the appropriate age-range below to view the correct checklist.": "اختر المرحلة العمرية المناسبة التي تتعلق بعمر طفلك",
	"Your Baby": "طفلك",
	"How can you help your child learn and grow?": "كيف يمكنك مساعدة طفلك على التعلم والنمو؟",
	"Enjoy doing fun and easy activities every day with your child": "استمتع بالقيام بأنشطة ممتعة وسهلة كل يوم مع طفلك",

	"What most babies do by this age": "ما يفعله معظم الأطفال في هذا العمر",
	"Child Name": "اسم الطفل",
	"Learn More About Activities": "تعرف على المزيد حول الأنشطة",
	"Your child will be singing this song in class throughout the week.": "سيغني طفلك هذه الأغنية في الفصل طوال الأسبوع.",
	"Ask them to teach it to you and your family.": "اطلب منهم تعليمه لك ولعائلتك.",
	"Learn More about the Practice": "تعرف على المزيد حول النشاط",
	"Age 0 - 24 Months": "العمر ٠ - ٢٤ شهر",
	"Age 2 - 3 years": "العمر ٢ - ٣ سنوات",
	"Age 3 - 4 years": "العمر ٣ - ٤ سنوات",
	"Age 4 - 5 years": " العمر ٤ - ٥ سنوات",

	Print: "طباعة",
	"Practice Resources": "موارد الممارسة",
	'In this practice, you will need to use "Facilitator Guidance" combine with "Reading Guidance"':
		'في هذه الممارسة ، ستحتاج إلى استخدام "دليل الميسر" مع "إرشادات القراءة"',
	"View Pdf": "عرض الملف",
	"©2022 Discovery Education Egypt. All Copyrights reserved":
		"حقوق الطبع والنشر © ديسكفري التعليمية . جميع الحقوق محفوظة لمؤسسة ديسكفري التعليمية.",
	"4 weeks": "٤ أسابيع",
	"4 Multidisciplinary Themes per Year": " ٤ مواضيع متعددة التخصصات في السنة",
	"4 Chapters per Theme": "٤ فصول لكل موضوع",
	"6 to 10 Lessons per Chapter": "من ٦ إلى ١٠ دروس لكل فصل",
	"Opportunities to engage in meaningful interactions between family members and children":"فرص الانخراط في تفاعلات هادفة بين أفراد الأسرة والأطفال",
	"Explore how parents and families can engage with their children through fun and easy Together Practice activities at home. Activities are designed to provide a bridge between content taught in the classroom and experiences at home. Learn about the activities designed to go with the Me and My Community Theme by watching the Parent and Family video.":"اكتشف كيف يمكن للوالدين والأسر التفاعل مع أطفالهم من خلال أنشطة ممتعة وسهلة لممارسة معًا في المنزل. تم تصميم الأنشطة لتوفير جسر بين المحتوى الذي يتم تدريسه في الفصل والخبرات في المنزل. تعرف على الأنشطة المصممة لتتناسب مع موضوع أنا ومجتمعي من خلال مشاهدة فيديو الوالدين والعائلة.",
	"Suggested daily schedule, features 3 STEM blocks with Together Activities":"الجدول اليومي المقترح ، يضم 3 كتل STEM مع أنشطة معًا",
	"Step-by-Step Differentiated instruction for Observers and Explorers":"إرشادات متباينة خطوة بخطوة للملاحظون و المستكشفون",
	"Supportive practices support nonverbal and newly verbal learners":"الممارسات الداعمة تدعم المتعلمين غير اللفظيين والمتعلمين حديثًا",
	"Videos for facilitators support specific activities and teaching strategies":"تدعم مقاطع الفيديو للميسرين أنشطة واستراتيجيات تعليمية محددة",
	"Multiple, daily opportunities to develop literacy skills through oral language development prompts":"فرص يومية متعددة لتطوير مهارات القراءة والكتابة",
	"Oral Language Development prompts support daily literacy instruction":"مطالبات تطوير اللغة الشفوية تدعم تعليم القراءة والكتابة اليومي",
	"12 Modules":"١٢ وحدة",
	"Channel":"مكتبة",
	"How Can you Help Children Learn and Grow?":"كيف يمكنك مساعدة الأطفال على التعلم والنمو؟",
	"Enjoy doing fun and easy activities every day with children chicklists":"استمتع بالقيام بأنشطة ممتعة وسهلة كل يوم مع قوائم الأطفال",
	"Learn More about Caregiver Guidance":"تعرف على المزيد حول إرشادات مقدم الرعاية",
	"Module":'وحدة',
	"Modules":'وحدات',
	"Fictional Story":"القصص الخيالية",
	"4 Nonfiction stories per Theme":"4 قصص واقعية لكل موضوع",
	"1 Fictional story per Theme":"1 قصة خيالية لكل موضوع",
	"Digital Library":'المكتبة الرقمية',
	"Explore As":"تصفح",
	"Select Theme":"اختر وحدة",
	"Select Week":"اختر أسبوع",
	"Developmental Milestones Checklists and Activities:": "قوائم فحص المعالم التنموية والأنشطة:",
	"Explore": "استكشف",
	"About STEMEY": "عن STEMEY",
	"Parent Program": "برنامج ولي الأمر",
	"Facilitator Program": "برنامج الميسر",
	"Caregiver Program": "برنامج مقدم الرعاية ",
	"Back": "الرجوع",
	"Program": "برنامج",
	"Explore how parents and families can engage with their children through fun and easy Together practice activities at class": "استكشف كيف يشترك اولياء الأمور و العائلات مع اولادهم من خلال انشطة مسلية في الفصل",
	"Download Guidance": "تحميل الملف الإرشادي",
	"View":"إظهار الكل",
	"Featured Videos":"الفيديوهات المميزة",
	"Video":"فيديو"
};
